import "./percentage-bar.scss";
import React, { useState } from "react";
import {
  MAXIMUM_CATEGORIES,
  MAXIMUM_CATEGORIES_PERCENT,
} from "../../commons/enum";
import { convertToFloat } from "../../utils/convert-number";

const mergeOtherCategories = (otherCategories) => {
  const otherCategory = otherCategories.reduce(
    (result, part) => {
      const { percentage } = part;
      return { ...result, percentage: percentage + result.percentage };
    },
    {
      title: "Other",
      percentage: 0,
    }
  );

  return otherCategory;
};

const splitCategoryParts = (categoryParts, maximumCategoryItemIndex) => {
  if (maximumCategoryItemIndex < 0) {
    return [categoryParts, []];
  }
  if (maximumCategoryItemIndex > MAXIMUM_CATEGORIES) {
    return [
      categoryParts.slice(0, MAXIMUM_CATEGORIES),
      categoryParts.slice(MAXIMUM_CATEGORIES),
    ];
  }
  return [
    categoryParts.slice(0, maximumCategoryItemIndex),
    categoryParts.slice(maximumCategoryItemIndex),
  ];
};

function PercentageBar({
  defaultTitle,
  chartColors = [],
  parts = [],
  chartIcons = [],
}) {
  const [selectedTitle, setSelectedTitle] = useState("");

  const handleMouseEnterPart = (part) => {
    setSelectedTitle(`${(part.percentage * 100).toFixed(1)}% ${part.title}`);
  };

  const handleMouseLeaveBar = () => {
    setSelectedTitle("");
  };

  const maximumCategoryItem = parts
    .slice()
    .sort(
      ({ percentage: percentage1 }, { percentage: percentage2 }) =>
        percentage2 - percentage1
    )
    .findIndex((part) => {
      return part.percentage * 100 < MAXIMUM_CATEGORIES_PERCENT;
    });

  // Group any categories after first 5 as one Group
  const [
    splittedCategoryFirstPart,
    splittedCategorySecondPart,
  ] = splitCategoryParts(parts, maximumCategoryItem);

  const [firstPartChartColors, secondPartChartColors] = splitCategoryParts(
    chartColors,
    maximumCategoryItem
  );
  const [firstPartChartIcons, secondPartChartIcons] = splitCategoryParts(
    chartIcons,
    maximumCategoryItem
  );

  const otherCategoryValue = mergeOtherCategories(splittedCategorySecondPart);

  return (
    <div className="percentage-bar">
      <div className="percentage-bar__title">
        {selectedTitle || defaultTitle}
      </div>
      <div className="percentage-bar__bar" onMouseLeave={handleMouseLeaveBar}>
        {splittedCategoryFirstPart.map((part, index) => (
          <div
            key={part.title}
            className="percentage-bar__part"
            onMouseEnter={() => {
              handleMouseEnterPart(part);
            }}
            style={{
              flexGrow: part.percentage * 10,
              "--index": index,
              "--background-color": firstPartChartColors[index],
            }}
          >
            <div className="percentage-bar__icon">
              {firstPartChartIcons[index]}
            </div>
            <div
              key={part.title}
              className="percentage-bar__value-part"
              onMouseEnter={() => {
                handleMouseEnterPart(part);
              }}
              style={{
                "--percentage-bar-width": `${part.percentage * 100}%`,
                "--index": index,
              }}
            >
              {convertToFloat(part.percentage * 100, 1)}%
            </div>
          </div>
        ))}
        {otherCategoryValue.percentage !== 0 && (
          <div
            style={{ flexGrow: otherCategoryValue.percentage * 10 }}
            className="percentage-bar__other-bar"
          >
            {splittedCategorySecondPart.map((part, index) => (
              <div
                key={part.title}
                className="percentage-bar__part"
                onMouseEnter={() => {
                  handleMouseEnterPart(part);
                }}
                style={{
                  flexGrow: part.percentage * 100,
                  "--index": index,
                  "--background-color": secondPartChartColors[index],
                }}
              >
                {part.percentage > 0.05 && (
                  <div className="percentage-bar__icon">
                    {secondPartChartIcons[index]}
                  </div>
                )}
              </div>
            ))}
            <div
              key={otherCategoryValue.title}
              className="percentage-bar__value-part"
              onMouseEnter={() => {
                handleMouseEnterPart(otherCategoryValue);
              }}
              style={{
                "--percentage-bar-width": `${
                  otherCategoryValue.percentage * 100
                }%`,
              }}
            >
              {convertToFloat(otherCategoryValue.percentage * 100, 1)}%
            </div>
          </div>
        )}
        {parts.length === 0 && (
          <div className="percentage-bar__part percentage-bar__part--disabled" />
        )}
      </div>
    </div>
  );
}

export { PercentageBar };
