import classNames from "classnames";
import React from "react";
import { useIsInEmbedMode } from "../../hooks";

export const PageBodyContent = ({ children, className = "" }) => {
  const isInEmbedMode = useIsInEmbedMode();
  return (
    <div
      className={classNames(
        "page-body-content",
        {
          "page-body-content--embed": isInEmbedMode,
        },
        className
      )}
    >
      {children}
    </div>
  );
};
