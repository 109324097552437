import "./simple-transaction-list.scss";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { formatNumberWithDollar } from "../../utils/numberFormater";

function SimpleTransactionList({ transactions, limit }) {
  const slicedTransactions = useMemo(
    () => transactions?.slice(0, limit) || [],
    [transactions, limit]
  );
  return (
    <div className="simple-transaction-list">
      {slicedTransactions.map((transaction) => {
        const transactionAmount = Number(transaction.amount);
        return (
          <div key={transaction.id} className="simple-transaction-list__row">
            <div className="simple-transaction-list__dot-container">
              <div className="simple-transaction-list__dot" />
            </div>
            <div className="simple-transaction-list-content">
              <div className="simple-transaction-list-content__top">
                <div className="simple-transaction-list-content__date">
                  {format(
                    new Date(transaction.transactionDate),
                    "MMM dd, yyyy"
                  )}
                </div>
                {transactionAmount >= 0 ? (
                  <div className="simple-transaction-list-content__amount simple-transaction-list-content__amount--credit">
                    {`+${formatNumberWithDollar(transactionAmount)}`}
                  </div>
                ) : (
                  <div className="simple-transaction-list-content__amount simple-transaction-list-content__amount--dedit">
                    {formatNumberWithDollar(transactionAmount)}
                  </div>
                )}
              </div>
              <div className="simple-transaction-list-content__bottom">
                {transaction.description}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export { SimpleTransactionList };
