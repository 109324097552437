import PropTypes from "prop-types";
import React from "react";
import { formatNumberWithDollar } from "../../utils/numberFormater";

const TransactionDetails = ({
  status = "",
  title = "",
  desc = "",
  value = 0,
  direction = "",
  balance = 0,
}) => {
  return (
    <div className="transaction-details">
      <div className="transaction-details__title">
        <p className="transaction-details__title--status">
          {`${title + (status === "pending" ? ` (Pending)` : "")}`}{" "}
        </p>
        <p className="transaction-details__title--description">{desc}</p>
      </div>
      <div className="transaction-details__value--debit">
        <p>
          {(direction === "debit" || value < 0) &&
            `-${formatNumberWithDollar(Math.abs(value), 2)}`}
        </p>
      </div>
      <div className="transaction-details__value--credit">
        <p>
          {direction === "credit" &&
            `+${formatNumberWithDollar(Math.abs(value), 2)}`}
        </p>
      </div>
      <div className="transaction-details__value--balance">
        <p className="my-auto cd-title">{formatNumberWithDollar(balance, 2)}</p>
      </div>
    </div>
  );
};

TransactionDetails.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  value: PropTypes.number,
};

export default TransactionDetails;
