import { makeStyles } from "@material-ui/core";
import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import React from "react";
import TransactionDetails from "./transaction-details";

const useStyles = makeStyles({
  paddingTop: {
    padding: "1.5rem 0px 16px",
  },
});

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const TransactionsList = ({ transactionList = [] }) => {
  const classes = useStyles();
  return (
    <Timeline className={classes.paddingTop}>
      {transactionList.length > 0 &&
        transactionList.map((transaction, index) => {
          const {
            id,
            date,
            transactionDate,
            description = "No data",
            direction = "",
            balance = 0,
            amount = 0,
            status = "",
          } = transaction;
          const displayDate = transactionDate || date;
          const displayDateObject = new Date(displayDate);
          const formattedDate = displayDate
            ? `${
                monthNames[displayDateObject.getMonth()]
              } ${displayDateObject.getDate()}, ${displayDateObject.getFullYear()}`
            : "No data";
          return (
            <TimelineItem key={id}>
              <TimelineSeparator>
                <TimelineDot />
                {index !== transactionList.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <TransactionDetails
                  status={status}
                  title={formattedDate}
                  desc={description}
                  value={amount}
                  direction={direction}
                  balance={balance}
                />
              </TimelineContent>
            </TimelineItem>
          );
        })}
    </Timeline>
  );
};

export default TransactionsList;
