import "./last-x-days-overview.scss";
import React from "react";
import { formatNumberWithDollar } from "../../utils/numberFormater";

function LastXDaysOverview({ title, parts = [] }) {
  const sum = parts.reduce((result, part) => part.amount + result, 0);
  return (
    <div className="last-x-days-overview">
      <div className="last-x-days-overview__title">{title}</div>
      {parts.map((part) => (
        <div className="last-x-days-overview__block" key={part.title}>
          <div className="last-x-days-overview__bar">
            <div
              className=""
              style={{
                flexGrow: part.amount,
                backgroundColor: part.color,
              }}
            />
            <div
              className=""
              style={{
                flexGrow: sum - part.amount || 1,
                backgroundColor: "#E9EDF2",
              }}
            />
          </div>
          <div className="last-x-days-overview__label">{`${formatNumberWithDollar(
            part.amount
          )} ${part.title}`}</div>
        </div>
      ))}
    </div>
  );
}

export { LastXDaysOverview };
