import classNames from "classnames";
import React, { useMemo, useState } from "react";
import TransactionsList from "./transactions-list";
import "./transactions-table.scss";

const TABS = {
  POSTED: "posted",
  PENDING: "pending",
};

const TransactionsTable = ({
  transactions = [],
  limit,
  balanceColumnLabel = "Balance",
}) => {
  const postedTransactions = transactions.filter(
    (transaction) => transaction.status === "posted"
  );
  const pendingTransactions = transactions.filter(
    (transaction) => transaction.status === "pending"
  );

  const [selectedTab, setSelectedTab] = useState(TABS.POSTED);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const slicedTransactions = useMemo(() => {
    const transactionsBasedOnTab =
      selectedTab === TABS.POSTED ? postedTransactions : pendingTransactions;
    return transactionsBasedOnTab.slice(0, limit);
  }, [limit, pendingTransactions, postedTransactions, selectedTab]);

  return (
    <div className="transactions-table">
      <div className="transactions-table__container">
        <div className="transactions-table__heading">
          <div className="transaction-heading-title">
            <div
              className={classNames("tab", {
                selected: selectedTab === TABS.POSTED,
              })}
              onClick={() => handleTabChange(TABS.POSTED)}
            >
              Account Transactions
            </div>
            {pendingTransactions.length > 0 && (
              <div
                className={classNames("tab", {
                  selected: selectedTab === TABS.PENDING,
                })}
                onClick={() => handleTabChange(TABS.PENDING)}
              >
                Pending Transactions
              </div>
            )}
          </div>
          <div className="transaction-heading-sub-title">
            <div className="transaction-heading-sub-title__detail">Details</div>
            <div className="transaction-heading-sub-title__value">Debits</div>
            <div className="transaction-heading-sub-title__value">Credits</div>
            <div className="transaction-heading-sub-title__value">
              {balanceColumnLabel}
            </div>
          </div>
        </div>
        <TransactionsList transactionList={slicedTransactions} />
      </div>
    </div>
  );
};

TransactionsTable.propTypes = {};

export { TransactionsTable };
