import classNames from "classnames";
import React from "react";
import { useIsInEmbedMode } from "../../hooks";

export const PageBody = ({ children }) => {
  const isInEmbedMode = useIsInEmbedMode();
  return (
    <div
      className={classNames("page-body", {
        "page-body--embed": isInEmbedMode,
      })}
    >
      {children}
    </div>
  );
};
