import "./with-load-more.scss";
import React from "react";

export const WithLoadMore = ({ children, onLoadMore, shown }) => {
  return (
    <div className="with-load-more">
      {children}
      {shown && (
        <div className="with-load-more__button-wrapper">
          <button
            className="with-load-more__button"
            type="button"
            onClick={onLoadMore}
          >
            Load more
          </button>
        </div>
      )}
    </div>
  );
};
